import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../fragments.generated';
export type InlineEditTagsLibraryItemFragment = { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, description: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, contentTagMemberships: Array<{ __typename?: 'ContentTagMembership', tagId: string, libraryItemId: string }> };

export const InlineEditTagsLibraryItemFragmentDoc = gql`
    fragment InlineEditTagsLibraryItem on LibraryItem {
  id
  name {
    ...TranslationSet
  }
  description {
    ...TranslationSet
  }
  contentTagMemberships {
    ...ContentTagMembership
  }
}
    ${TranslationSetFragmentDoc}
${ContentTagMembershipFragmentDoc}`;