import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { CoverImageFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, TranslationSetFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../../fragments.generated';
export type AssignedByContentMembershipPathFragment = { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null }, draftVersion: { __typename?: 'PathVersion', id: string } };

export type AssignedByPathContentMembershipFragment = { __typename?: 'PathContentMembership', id: number, uuid: string, pathVersion?: { __typename?: 'PathVersion', id: string, path: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null }, draftVersion: { __typename?: 'PathVersion', id: string } } } | null };

export type AssignedByCourseFragment = { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } };

export const AssignedByContentMembershipPathFragmentDoc = gql`
    fragment AssignedByContentMembershipPath on Path {
  id
  libraryItem {
    id
    name {
      en
    }
    coverImage {
      ...CoverImage
    }
  }
  draftVersion {
    id
  }
}
    ${CoverImageFragmentDoc}`;
export const AssignedByPathContentMembershipFragmentDoc = gql`
    fragment AssignedByPathContentMembership on PathContentMembership {
  id
  uuid
  pathVersion {
    id
    path {
      ...AssignedByContentMembershipPath
    }
  }
}
    ${AssignedByContentMembershipPathFragmentDoc}`;
export const AssignedByCourseFragmentDoc = gql`
    fragment AssignedByCourse on Course {
  id
  libraryItem {
    id
    name {
      en
    }
    coverImage {
      ...CoverImage
    }
  }
}
    ${CoverImageFragmentDoc}`;